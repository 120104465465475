import { injectReducer, injectSagas, } from 'store'


export default (store) => ({
  path: '/products/:accountId/pricing-lists/:pricingListId',

  /*  Async getComponent when route matches   */
  getComponent(nextState, cb) {
    /*  Webpack (split point) - async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack -require callback  */
      const PricingListDetailsContainer = require('./containers/PricingListDetailsContainer').default
      const reducer = require('./modules/reducer').default
      const sagas = require('./modules/sagas').default

      /*  Add the reducer to the store on key 'pricingLists'  */
      injectReducer(store, { key: 'pricingListDetails', reducer, })
      injectSagas(store, sagas)

      /*  Return getComponent   */
      cb(null, PricingListDetailsContainer)

    /* Webpack named bundle   */
    }, 'pricing-list-details')
  },
})

import _ from 'lodash'
import os from 'os'
import Moment from 'moment'
import { extendMoment, } from 'moment-range'

const commafy = function (number) {
  if (!number) return false

  const str = number.toString().split('.')
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ')
  }
  return str.join('.')
}

const ColorSteps = (() => {
  /**
   * Convert any color string to an [r,g,b,a] array.
   * @author Arjan Haverkamp (arjan-at-avoid-dot-org)
   * @param {string} color Any color. F.e.: 'red', '#f0f', '#ff00ff', 'rgb(x,y,x)', 'rgba(r,g,b,a)', 'hsl(180, 50%, 50%)'
   * @returns {array} [r,g,b,a] array. Caution: returns [0,0,0,0] for invalid color.
   * @see https://gist.github.com/av01d/8f068dd43447b475dec4aad0a6107288
   */
  const colorValues = (color) => {
    const div = document.createElement('div')
    div.style.backgroundColor = color
    document.body.appendChild(div)
    let rgba = getComputedStyle(div).getPropertyValue('background-color')
    div.remove()

    if (rgba.indexOf('rgba') === -1) {
      rgba += ',1' // convert 'rgb(R,G,B)' to 'rgb(R,G,B)A' which looks awful but will pass the regxep below
    }

    return rgba.match(/[\.\d]+/g).map((a) => +a)
  }

  /**
   * Get color steps (gradient) between two colors.
   * @author Arjan Haverkamp (arjan-at-avoid-dot-org)
   * @param {string} colorStart Any color. F.e.: 'red', '#f0f', '#ff00ff', 'rgb(x,y,x)', 'rgba(r,g,b,a)', 'hsl(180, 50%, 50%)'
   * @param {string} colorEnd Any color
   * @param {int} steps Number of color steps to return
   * @returns {array} Array of 'rgb(r,g,b)' or 'rgba(r,g,b,a)' arrays
   */
  const getColorSteps = (colorStart, colorEnd, steps) => {
    const start = colorValues(colorStart)
    const end = colorValues(colorEnd)
    const opacityStep = (end[3] * 100 - start[3] * 100) / steps
    const colors = []
    let alpha = 0
    let opacity = start[3] * 100

    colors.push(colorStart)
    if (steps > 2) {
      for (let i = 1; i < (steps - 1); i++) {
        alpha += 1.0 / steps
        opacity += opacityStep

        const c = [
          Math.round(end[0] * alpha + (1 - alpha) * start[0]),
          Math.round(end[1] * alpha + (1 - alpha) * start[1]),
          Math.round(end[2] * alpha + (1 - alpha) * start[2])
        ]

        colors.push(
          opacity == 100 ? `rgb(${c[0]},${c[1]},${c[2]})` : `rgba(${c[0]},${c[1]},${c[2]},${opacity / 100})`
        )
      }
    }
    if (steps > 1) {
      colors.push(colorEnd)
    }

    return colors
  }

  return {
    colorValues,
    getColorSteps
  }
})()

export const getCardBrand = (paymentType, returnLowercase = false) => {
  const cardBrandMap = {
    v: 'Visa',
    visa: 'Visa',
    mc: 'MasterCard',
    mastercard: 'MasterCard',
    a: 'AmEx',
    americanexpress: 'AmEx',
    amex: 'AmEx',
    d: 'Discover',
    discover: 'Discover',
    ach: 'ACH',
    checking: 'ACH',
    savings: 'ACH'
  }

  return returnLowercase
    ? cardBrandMap[paymentType.toLowerCase()].toLowerCase()
    : cardBrandMap[paymentType.toLowerCase()]
}

function chartSettings(color = 'rgb(11, 160, 251)') {
  return {
    lineTension: 0.001,
    pointHitRadius: 10,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 0,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: color,
    borderWidth: 2,
    pointBackgroundColor: color,
    pointBorderColor: color,
    pointRadius: 0,
    pointBorderWidth: 0,
    fill: 'origin',
  }
}

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const moneyFormatterCompact = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 2,
})


function _formatOverviewData(a, b) {
  const aOverviewData = Object.values(a)
  const aValues = []
  const aLabels = []
  const bOverviewData = b ? Object.values(b) : []
  const bValues = []
  const bLabels = []

  const formatData = (data, valueHolder, labelHolder) => {
    data.map((d) => {
      valueHolder.push(d.amount || d.count)
      if (d.time) {
        labelHolder.push(Moment.utc(d.time).format('M/D/YYYY, ha'))
      }
      if (d.date) {
        labelHolder.push(Moment.utc(d.date).utc().format('M/D/YYYY'))
      }
      return false
    })
  }

  formatData(aOverviewData, aValues, aLabels)
  if (b) formatData(bOverviewData, bValues, bLabels)

  return {
    labels: aLabels,
    datasets: [{
      yAxisID: 'A',
      label: aLabels,
      data: aValues,
      ...chartSettings('rgb(11, 160, 251)')
    }, {
      yAxisID: 'B',
      label: bLabels,
      data: bValues,
      ...chartSettings('rgba(13, 34, 64, 0.5)')
    }]
  }
}

function _formatOverviewDataV2(a, b) {
  const aOverviewData = Object.values(a)
  const aValues = []
  const aLabels = []
  const bOverviewData = b ? Object.values(b) : []
  const bValues = []
  const bLabels = []

  const formatData = (data, valueHolder, labelHolder) => {
    data.map((d) => {
      valueHolder.push(d.amount || d.count)
      if (d.time) {
        labelHolder.push(Moment(d.time).format('M/D/YYYY, ha'))
      }
      if (d.date) {
        labelHolder.push(Moment.utc(d.date).format('M/D/YYYY'))
      }
      return false
    })
  }

  formatData(aOverviewData, aValues, aLabels)
  if (b) formatData(bOverviewData, bValues, bLabels)

  return {
    labels: aLabels,
    datasets: [{
      yAxisID: 'A',
      label: aLabels,
      data: aValues,
      ...chartSettings('rgb(11, 160, 251)')
    }, {
      yAxisID: 'B',
      label: bLabels,
      data: bValues,
      ...chartSettings('rgba(13, 34, 64, 0.5)')
    }]
  }
}

function _grabDateOrTime(data) {
  if (data.time) {
    return Moment(data.time).format('MMMM Do, YYYY')
  }
  if (data.date) {
    return Moment.utc(data.date).format('MMMM Do, YYYY')
  }
  return ''
}

function percentageDifference(current, lastYear) {
  const copyCurrent = current ? parseFloat(current) : 0
  const copyLastYear = lastYear ? parseFloat(lastYear) : 0
  if (copyCurrent === copyLastYear) {
    return '0%'
  }
  if (copyCurrent === 0) {
    return '-100%'
  }
  if (copyLastYear === 0) {
    return '+100%'
  }
  const difference = copyCurrent - copyLastYear
  const percentage = Math.round(((difference / Math.abs(copyLastYear)) * 100) * 10) / 10
  return `${percentage >= 0 ? '+' : ''}${new Intl.NumberFormat().format(percentage)}%`
}

function pillColor(current, lastYear, pillColorFlip = false) {
  const copyCurrent = current ? parseFloat(current) : 0
  const copyLastYear = lastYear ? parseFloat(lastYear) : 0
  const negativeColor = pillColorFlip ? '#90EE90' : '#FFCCCB'
  const positiveColor = pillColorFlip ? '#FFCCCB' : '#90EE90'
  if (copyCurrent === copyLastYear) {
    return 'rgba(0, 0, 0, 0.1)'
  }
  if (copyCurrent === 0) {
    return negativeColor
  }
  if (copyLastYear === 0) {
    return positiveColor
  }
  const difference = copyCurrent - copyLastYear
  return difference >= 0 ? positiveColor : negativeColor
}

function pillMaker(current, lastYear, pillColorFlip = false) {
  return `
    <div class='pill' style='background:${pillColor(current, lastYear, pillColorFlip)}'>
      ${percentageDifference(current, lastYear)}
    </div>`
}

function chartOptions(format = 'number', title = '', min = 0, max = 0, pillColorFlip = false) {
  return {
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
        fontSize: 9,
        fontFamily: 'Roboto, sans-serif',
      }
    },
    layout: {
      padding: 5,
    },
    maintainAspectRatio: false,
    tooltips: {
      position: 'nearest',
      mode: 'index',
      xPadding: 10,
      yPadding: 10,
      cornerRadius: 10,
      callbacks: {
        label(tooltipItem, data) {
          return (format === 'money')
            ? `${moneyFormatterCompact.format(tooltipItem.value)}`
            : `${new Intl.NumberFormat().format(tooltipItem.value)}`
        },
        beforeLabel(tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].label
            ? `${data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index]}: `
            : ''
        },
        title(tooltipItem, data) {
          return title
        }
      },
      enabled: false,
      custom(tooltipModel) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip')

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div')
          tooltipEl.id = 'chartjs-tooltip'
          tooltipEl.classList.add('custom-tooltip')
          document.body.appendChild(tooltipEl)
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0
          return
        }

        // Set caret position
        tooltipEl.classList.remove('above', 'below', 'no-transform')
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign)
        } else {
          tooltipEl.classList.add('no-transform')
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || []
          const bodyLines = tooltipModel.body.map((bodyItem) => bodyItem)

          let innerHtml = '<thead>'

          titleLines.forEach(() => {
            const currentValue = parseFloat(tooltipModel.dataPoints[0].value || 0)
            const lastYearValue = parseFloat(tooltipModel.dataPoints[1]
              ? tooltipModel.dataPoints[1].value
              : 0
            )
            innerHtml += `<tr><th>
        <div class='title'><b>${title}</b></div>
        ${tooltipModel.body.length === 2 && tooltipModel.dataPoints[1]
    ? pillMaker(currentValue, lastYearValue, pillColorFlip)
    : ''}
        </th></tr>
        `
          })
          innerHtml += '</thead><tbody>'

          bodyLines.forEach((body, i) => {
            const date = body.before[0]
            const colors = tooltipModel.labelColors[i]
            let style = `background: ${colors.backgroundColor}`
            style += `; border-color: ${colors.borderColor}`
            style += '; border-width: 2px'
            style += '; width: 10px'
            style += '; height: 10px'
            style += '; display: inline-block'
            style += '; border-radius: 50%'
            style += '; margin-right: 5px'
            const span = `<span class="chartjs-tooltip-key" style="${style}"></span>`
            innerHtml += `<tr><td><div class='row-one'>${span}${date}</div><div>${body.lines[0]}</div></td></tr>`
          })
          innerHtml += '</tbody>'

          let tableRoot = tooltipEl.querySelector('table')
          if (!tableRoot) {
            tableRoot = document.createElement('table')
            tooltipEl.appendChild(tableRoot)
          }
          tableRoot.innerHTML = innerHtml
        }

        // Calculate tooltip position
        const canvas = this._chart.canvas.getBoundingClientRect()
        const positionY = canvas.top
        const positionX = canvas.left
        const topMargin = 10
        const chartCenterX = canvas.width / 2

        // Display, position, and set styles for font
        if (tooltipModel.caretX < chartCenterX) {
          tooltipEl.style.left = `${positionX + tooltipModel.caretX}px`
        } else {
          tooltipEl.style.left = `${(positionX + tooltipModel.caretX) - tooltipEl.offsetWidth}px`
        }
        tooltipEl.style.opacity = 1
        tooltipEl.style.position = 'fixed'
        tooltipEl.style.top = `${(positionY + tooltipModel.caretY) - tooltipEl.clientHeight - topMargin}px`
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
        tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
        tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`
        tooltipEl.style.pointerEvents = 'none'
      }
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      xAxes: [{
        display: false,
        gridLines: {
          display: false,
        },
      }],
      yAxes: [
        {
          id: 'A',
          type: 'linear',
          stack: 1,
          stackWeight: 1,
          display: false,
          position: 'bottom',
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            min,
            max,
            callback(value, index, values) {
              return (value === Math.min(...values) || value === Math.max(...values))
                ? value
                : null
            }
          }
        },
        {
          id: 'B',
          type: 'linear',
          stack: 1,
          stackWeight: 2,
          display: false,
          position: 'bottom',
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            min,
            max,
          },
        },
      ],
    },
  }
}

export default {
  commafy,
  ColorSteps,
  chartSettings,
  moneyFormatter,
  moneyFormatterCompact,
  _formatOverviewData,
  _formatOverviewDataV2,
  _grabDateOrTime,
  percentageDifference,
  pillColor,
  pillMaker,
  chartOptions,

  /**
   * Based on bytes size, returns a human readable form.
   * @param {Integer} size (byte)
   */
  getReadableFileSize(size) {
    let i = 0
    const byteUnits = [' bytes', ' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB', ]
    while (size > 1000) {
      size /= 1000
      i++
    }

    return Math.max(size, 0.1).toFixed(1) + byteUnits[i]
  },

  /**
   * Formats the value to the dollar format
   * @param {number} value - The value to be formatted
   * @returns {string} The formatted value
   */
  formatValueToDollar(value) {
    let castedValue = 0

    if (_.isString(value)) {
      const aux = value.replace(/,/g, '')
      castedValue = Number(aux)
    } else if (_.isNumber(value)) {
      castedValue = value
    } else {
      throw new TypeError('Invalid argument: `value` has to be either a string or a number.')
    }

    const formattedValue = commafy(Math.abs(castedValue).toFixed(2))
    return castedValue < 0 ? `-$${formattedValue}` : `$${formattedValue}`
  },

  /**
   * Formats the value to display on the UI.
   * If `value` is empty, dashes are returned instead
   * @param {*} value - Any PRIMITIVE value
   * @param {...Array} - Arrays composed of a callback function and its arguments
   * @returns {String} The formatted value
   */
  prepareValueToDisplay(value) {
    if (_.isNil(value)) {
      return '---'
    }
    const remainingArgs = Array.prototype.slice.call(arguments, 1, arguments.length)

    remainingArgs.forEach((callbackData) => {
      if (_.isArray(callbackData)) {
        const callback = callbackData[0]
        const context = callbackData[1]
        const args = callbackData.slice(2, callbackData.length)
        value = callback.apply(context, args)
      } else if (_.isFunction(callbackData)) {
        value = callbackData.call(null, value)
      } else {
        throw new TypeError(`Invalid argument. The callbacks args have to be\
            either functions or arrays composed of a function and its arguments`)
      }
    })

    return value
  },

  /**
   * Capitalizes the first letter of the provided word. If the first character is
   * not a letter, iterates until finding one. Otherwise, returns the word as it is.
   * @param {String} word - The word to capitalize
   * @returns {String}
   */
  capitalizeFirstLetter(word) {
    let capitalized = false
    let result = ''

    for (let i = 0, j = word.length; i < j && !capitalized; ++i) {
      const crtLetter = word[i]

      if (/\w/.test(crtLetter)) {
        result += crtLetter.toUpperCase()
        result += word.slice(i + 1, j)
        capitalized = true
      } else {
        result += crtLetter
      }
    }

    return result
  },

  /**
   * Parses the `type` value for the reports
   * @param {String} type
   * @returns {String}
   */
  parseType(type) {
    switch (type) {
      case 'process':
        return 'sale'

      default:
        return type
    }
  },

  /**
   * Parses an object array and convert it into a string to export as CSV
   * @param {Object[]} dataArr
   * @returns {String}
   */
  parseToExportToCSV(dataArr) {
    if (!dataArr) {
      throw new TypeError('You must include some data')
    }

    let auxArr = []
    let result = ''

    dataArr.map((data) => {
      _.keys(data).map((key) => {
        auxArr.push(data[key])
      })

      result += auxArr.join(';') + os.EOL
      auxArr = []
    })

    return result
  },

  /**
   * Returns the range between the provided dates
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {String} unit
   * @returns {Date[]}
   */
  getDateRange(startDate, endDate, unit = 'day') {
    const moment = extendMoment(Moment)
    const range = moment.range(startDate, endDate)

    return Array.from(range.by(unit)).map((m) => m.toDate())
  },

  getStateFromZip(zipcode) {
    const zip = parseInt(zipcode.toString(), 10)

    const states = [{ min: 35000, max: 36999, code: 'AL', },
      { min: 99500, max: 99999, code: 'AK', }, { min: 85000, max: 86999, code: 'AZ', },
      { min: 71600, max: 72999, code: 'AR', }, { min: 90000, max: 96699, code: 'CA', },
      { min: 80000, max: 81999, code: 'CO', }, { min: 6000, max: 6999, code: 'CT', },
      { min: 19700, max: 19999, code: 'DE', }, { min: 32000, max: 34999, code: 'FL', },
      { min: 30000, max: 31999, code: 'GA', }, { min: 96700, max: 96999, code: 'HI', },
      { min: 83200, max: 83999, code: 'ID', }, { min: 60000, max: 62999, code: 'IL', },
      { min: 46000, max: 47999, code: 'IN', }, { min: 50000, max: 52999, code: 'IA', },
      { min: 66000, max: 67999, code: 'KS', }, { min: 40000, max: 42999, code: 'KY', },
      { min: 70000, max: 71599, code: 'LA', }, { min: 3900, max: 4999, code: 'ME', },
      { min: 20600, max: 21999, code: 'MD', }, { min: 1000, max: 2799, code: 'MA', },
      { min: 48000, max: 49999, code: 'MI', }, { min: 55000, max: 56999, code: 'MN', },
      { min: 38600, max: 39999, code: 'MS', }, { min: 63000, max: 65999, code: 'MO', },
      { min: 59000, max: 59999, code: 'MT', }, { min: 27000, max: 28999, code: 'NC', },
      { min: 58000, max: 58999, code: 'ND', }, { min: 68000, max: 69999, code: 'NE', },
      { min: 88900, max: 89999, code: 'NV', }, { min: 3000, max: 3899, code: 'NH', },
      { min: 7000, max: 8999, code: 'NJ', }, { min: 87000, max: 88499, code: 'NM', },
      { min: 10000, max: 14999, code: 'NY', }, { min: 43000, max: 45999, code: 'OH', },
      { min: 73000, max: 74999, code: 'OK', }, { min: 97000, max: 97999, code: 'OR', },
      { min: 15000, max: 19699, code: 'PA', }, { min: 300, max: 999, code: 'PR', },
      { min: 2800, max: 2999, code: 'RI', }, { min: 29000, max: 29999, code: 'SC', },
      { min: 57000, max: 57999, code: 'SD', }, { min: 37000, max: 38599, code: 'TN', },
      { min: 75000, max: 79999, code: 'TX', }, { min: 88500, max: 88599, code: 'TX', },
      { min: 84000, max: 84999, code: 'UT', }, { min: 5000, max: 5999, code: 'VT', },
      { min: 22000, max: 24699, code: 'VA', }, { min: 20000, max: 20599, code: 'DC', },
      { min: 98000, max: 99499, code: 'WA', }, { min: 24700, max: 26999, code: 'WV', },
      { min: 53000, max: 54999, code: 'WI', }, { min: 82000, max: 83199, code: 'WY', }
    ]

    const state = states.filter((s) => s.min <= zip && s.max >= zip)

    if (state.length === 0) {
      return false
    } else if (state.length > 1) {
      console.error('Error: found two states')
    }
    return state[0].code
  },

  getProvinceFromPostalCode(postalCode) {
    // eslint-disable-next-line max-len
    const provLookup = { a: 'NL', b: 'NS', c: 'PE', e: 'NB', g: 'QC', h: 'QC', j: 'QC', k: 'ON', l: 'ON', m: 'ON', n: 'ON', p: 'ON', r: 'MB', s: 'SK', t: 'AB', v: 'BC', x: 'NT', y: 'YT' }
    return provLookup[postalCode.charAt(0).toLowerCase()]
  },

  getDatesInRangeArray(startDate, endDate, type, format) {
    const fromDate = Moment(startDate)
    const toDate = Moment(endDate)
    const diff = toDate.diff(fromDate, type)
    const range = []
    for (let i = 0; i <= diff; i++) {
      range.push(Moment(startDate).add(i, type).format(format))
    }
    return range
  },

  async resizeImageFile(file, size = 128) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = size
    canvas.height = size

    const bitmap = await createImageBitmap(file)
    const { width, height } = bitmap

    const ratio = Math.max(size / width, size / height)

    const x = (size - (width * ratio)) / 2
    const y = (size - (height * ratio)) / 2

    ctx.drawImage(bitmap, 0, 0, width, height, x, y, width * ratio, height * ratio)

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob)
      }, 'image/webp', 1)
    })
  },

  arrayBufferToBase64(buffer) {
    return btoa(
      new Uint8Array(buffer)
        .reduce((data, byte) => `${data}${String.fromCharCode(byte)}`, '')
    )
  },

  csvToJson(csv) {
    // replace windows carriage returns
    // split the csv string on the new lines
    // trim each line and replace multiple spaces with just one.
    // filter out the empty lines
    const [headers, ...rows] =
      csv.replace(/\r/gm, '')
        .split('\n')
        .map((s) => s.trim().replace(/ +(?= )/g, ''))
        .filter(Boolean)
    const headersArr = headers.split(',')

    return rows.map((item) => {
      const items = item.split(',')
      return headersArr.reduce((acc, key, index) => ({
        ...acc,
        [key.trim()]: items[index].trim(),
      }), {})
    })
  },

}
